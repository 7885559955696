import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

interface OptionalGatsbyImageProps
  extends Omit<React.ComponentProps<typeof GatsbyImage>, 'image'> {
  image?: Parameters<typeof getImage>[0];
}

export const OptionalGatsbyImage = ({
  image,
  ...props
}: OptionalGatsbyImageProps) => {
  const gatsbyImageData = image && getImage(image);
  return gatsbyImageData ? (
    <GatsbyImage image={gatsbyImageData} {...props} />
  ) : null;
};
