import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { OptionalGatsbyImage } from '@/atomic/atoms/OptionalGatsbyImage';
import { DataContainsNode, useTransformNode } from '../../hooks/useTransformer';
import { MainTitle, TitleWrapper } from './CommonElement';
import Nl2Br from './Nl2Br';
import SharedCss from './SharedCss';

const WhyRicohWrapper = styled.div`
  padding: 50px 30px;

  /** Common Css **/
  ${SharedCss}
`;

const WhyRicohTitle = styled(TitleWrapper)`
  body & {
    @media (min-width: 1024px) {
      margin-bottom: 40px;
    }
  }
`;

const WhyRicohList = styled.ol`
  body & {
    /* TAB */
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }

    /* PC */
    @media (min-width: 1024px) {
      max-width: 1440px;
      margin: 0 auto !important;
    }
  }
`;

const WhyRicohListItem = styled.li`
  body & {
    position: relative;
    padding: 45px 0 0;

    /* List / Item / Image */

    .WhyRicoh__ListItemImage {
      border: 1px solid #000;
    }

    /* TAB */
    @media (min-width: 768px) {
      width: 31%;
    }

    /* PC */
    @media (min-width: 1024px) {
      width: 30%;
      padding: 70px 0 0;

      &::after {
        font-size: 36px;
      }

      .WhyRicoh__ListItemImage {
        width: 100%;
      }
    }
  }
`;

const WhyRicohListItemTitle = styled.h3`
  body & {
    font-weight: 300;
    text-align: center;
    /* added because of Gastby Image wrapped by div tag /*
    padding-top: 5px;
  
    /* TAB */
    @media (min-width: 768px) {
      font-size: 18px;
    }

    /* PC */
    @media (min-width: 1024px) {
      font-size: 24px;
    }
  }
`;

const WhyRicohListItemDisc = styled.p`
  body & {
    font-size: 14px;

    /* PC */
    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }
`;

const DATA_QUERY = graphql`
  query WhyRicohNewData {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "projects-why-ricoh" } } }
    ) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            templateKey
            mainSubText
            whyPoints {
              title
              desc
              img {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`;

function PointItem(item: GatsbyTypes.MarkdownRemarkFrontmatterWhyPoints) {
  return (
    <>
      <WhyRicohListItem>
        <OptionalGatsbyImage
          image={item?.img}
          alt={item.title || ''}
          className={'WhyRicoh__ListItemImage'}
        />
        <WhyRicohListItemTitle>{item?.title}</WhyRicohListItemTitle>
        <WhyRicohListItemDisc>{item?.desc}</WhyRicohListItemDisc>
      </WhyRicohListItem>
    </>
  );
}

const WhyRicoh: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.WhyRicohNewDataQuery>(
    DATA_QUERY,
  ) as DataContainsNode;
  const { frontmatter } = useTransformNode(data);
  const points = frontmatter?.whyPoints;

  return (
    <WhyRicohWrapper>
      <WhyRicohTitle>
        <MainTitle>
          <Nl2Br text={frontmatter?.mainSubText} sp={true} />
        </MainTitle>
      </WhyRicohTitle>
      <WhyRicohList>
        {points?.map((item, index) => (
          <PointItem
            img={item?.img}
            title={item?.title}
            desc={item?.desc}
            key={'point-item' + index}
            altText={item?.altText}
          />
        ))}
      </WhyRicohList>
    </WhyRicohWrapper>
  );
};

export default WhyRicoh;
